
import NavbarRoot from './component/Navbar/NavbarRoot';
import FirstPageCard from './component/FirstPageCard/FirstCard';
import SecondCard from './component/SecondCard/index';

export default function LandingPage() {


  return (
    <div>
    <>
    <NavbarRoot/>
    <FirstPageCard/>
    <SecondCard/>
    </>
  </div>
  );
}
